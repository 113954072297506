import { memo, useCallback, useEffect, useMemo } from "react";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import MultiRangeUnitConversionEditableContentProps from "./utilities/MultiRangeUnitConversionEditableContent.props";
import ToggleBaseContainer from "../ToggleBaseContainer/ToggleBaseContainer";
import OperatingRangeContainer from "../OperatingRangeContainer/OperatingRangeContainer";
import multiRangeUnitConversionSchema from "schemas/multiRangeUnitConversionSchema.schema";
import HealthRangeContainer from "../HealthRangeContainer/HealthRangeContainer";
//import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import { Resolver, useForm } from "react-hook-form";
import { ObjectSchema, ValidationError } from "yup";
import {
    stringifyMultiRangeUnitConversion,
    convertStringMultiRangeUnitConversionToNumbers,
} from "utils/stringifyMultiRangeUnitConversion";
import StringMultiRangeUnitConversion from "types/StringMultiRangeUnitConversion";
import MultiRangeUnitConversionErrors from "types/MultiRangeUnitConversionErrors";
import convertStringToDotNotation from "utils/convertStringToDotNotation";
import MultiRangeUnitConversion from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MultiRangeUnitConversion.type";
import convertMultiRangeUnitConversionDimension from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemNonGeneric/MultiRangeUnitConversionMetricContainer/utilities/convertMultiRangeUnitConversionDimension";
import UnitSelector from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentEdit/CurrentDeviceCardContentEditDimensions/CurrentDeviceCardContentEditDimensionsItem/MultiRangeUnitConversionEditDrawer/MultiRangeUnitConversionEditableContent/UnitSelector/UnitSelector";

/**
 * Our default data for the RHF form. Because text fields handle numbers as strings,
 * we must remember to convert these fields to numbers in the final computation
 */

/**
 * An all-purpose component meant to manage the multiRangeEditableFields. Fields that can allow for an array of ranges
 * per health status. As long as they fall within the overall operating range.
 */
export default memo(function MultiRangeUnitConversionEditableContent({
    dimension,
    unit,
    baseUnits,
    setUnit,
    setHasErrors,
    setFormState,
}: MultiRangeUnitConversionEditableContentProps): JSX.Element {
    /**
     * Converts @see MultiRangeUnitConversion to an acceptable object for
     * this component to use (i.e., removes the scale)
     */
    const convertDimensionToForm = useCallback(
        (
            dimensions: MultiRangeUnitConversion,
        ): StringMultiRangeUnitConversion => {
            try {
                const newForm: StringMultiRangeUnitConversion =
                    stringifyMultiRangeUnitConversion(dimensions);
                return newForm;
            } catch (err) {
                console.error("Could not convert dimension type to form", err);
                throw new Error(
                    "Could not convert dimension dimension to relevant type",
                );
            }
        },
        [],
    );

    /**
     *  Output: We need a default value for if the expected dimension is not found,
     *  rather than put through an error, set an empty state, which is later updated
     *  with the imported dimensions.
     */
    const stringDimension: StringMultiRangeUnitConversion = useMemo(() => {
        try {
            const convertedDimension = convertMultiRangeUnitConversionDimension(
                dimension.multiRangeUnitConversion,
                unit,
            );
            return convertDimensionToForm(convertedDimension);
        } catch (err) {
            console.error(
                "Could not resolve dimension type, aborting setting dimensions",
                err,
            );
            throw new Error("Could not find multirange");
        }
    }, [dimension, unit]);

    /**
     * Validates the form values against the multiRangeSafeVal schema.
     * As a side effect, if the errors are found alerts the global form handler @see CurrentDeviceCardContentEdit disable save fields. Does not update global form state
     * If no errors, updates global form state
     *
     * @param {StringMultiRangeUnitConversion} values - The current form values.
     * @returns {ValidationError} - An object containing validation errors, if any. This gets resolved into a format that RHF understands
     */
    const useYupValidationResolver = (
        validationSchema: ObjectSchema<StringMultiRangeUnitConversion>,
    ): ((data: StringMultiRangeUnitConversion) => Promise<{
        values: StringMultiRangeUnitConversion;
        errors: MultiRangeUnitConversionErrors;
    }>) =>
        useCallback(
            async (data: StringMultiRangeUnitConversion) => {
                try {
                    //Attempt to valiate form values
                    const values = await validationSchema.validate(data, {
                        abortEarly: false,
                    });

                    //Assuming they pass, convert them to their proper type as numbers
                    const valuesAsNum: MultiRangeUnitConversion =
                        convertStringMultiRangeUnitConversionToNumbers(values);

                    // Convert the values back to base units
                    const baseValues = convertMultiRangeUnitConversionDimension(
                        valuesAsNum,
                        baseUnits,
                    );

                    // //Submit them to a global form state and enable submit buttons
                    setFormState(baseValues);
                    setHasErrors(false);
                    return {
                        values,
                        errors: {} as unknown as MultiRangeUnitConversionErrors,
                    };
                } catch (errors) {
                    //We are only worried about Yup.ValidationErrors here
                    if (ValidationError.isError(errors)) {
                        //Disable submit buttons, since we found some errors
                        setHasErrors(true);
                        const resolvedErrors = {
                            values: data,
                            errors: errors.inner.reduce(
                                (allErrors, currentError) => {
                                    //RHF uses dot notation to locate fields/inputs
                                    //so we need to convert the yup paths (which use ["0"]/[0]), to dots
                                    return {
                                        ...allErrors,
                                        [(currentError.path &&
                                            convertStringToDotNotation(
                                                currentError.path,
                                            )) ||
                                        "unknown"]: {
                                            type:
                                                currentError.type ??
                                                "validation",
                                            message: currentError.message,
                                        },
                                    };
                                },
                                {},
                            ),
                        };
                        return resolvedErrors;
                    } else {
                        //TODO: Handle other types of errors, most likely with the toastit note
                        console.error(
                            "An unexpected error occurred during validation:",
                            errors,
                        );

                        return {
                            values: data,
                            errors: {
                                default:
                                    "An unexpected error occurred. Please try again or contact support.",
                            },
                        };
                    }
                }
            },
            [validationSchema],
        );

    const resolver: Resolver<StringMultiRangeUnitConversion, unknown> =
        useYupValidationResolver(multiRangeUnitConversionSchema);

    const {
        control,
        trigger,
        formState: { errors },
        reset,
    } = useForm<StringMultiRangeUnitConversion>({
        defaultValues: stringDimension,
        resolver: resolver,
        mode: "onChange",
    });

    useEffect(() => {
        // Reset form with new converted values when units change
        reset(stringDimension);
    }, [unit, reset]);

    return (
        <form
            onSubmit={(): void => {
                return;
            }}
            className="flex flex-1 flex-col"
        >
            <ToggleBaseContainer
                title={`Toggle Default Reading`}
                control={control}
            />
            <div className="flex flex-row mt-2 mb-2">
                <span className="capitalize mr-2"> Display Units:{"   "}</span>
                <UnitSelector
                    unit={unit}
                    baseUnit={dimension.multiRangeUnitConversion.baseUnits}
                    setUnit={setUnit}
                ></UnitSelector>
            </div>
            <OperatingRangeContainer
                control={control}
                trigger={trigger}
                errors={errors}
            />
            <ul className="flex flex-col flex-wrap">
                {(["safe", "warning", "unsafe"] as HEALTH_STATUS[]).map(
                    (health) => {
                        return (
                            <HealthRangeContainer
                                key={health}
                                control={control}
                                health={health}
                                trigger={trigger}
                                errors={errors}
                            />
                        );
                    },
                )}
            </ul>
        </form>
    );
});
