import lengthConversions from "./conversions/lengthConversions";
import temperatureConversions from "./conversions/temperatureConversions";
import pressureConversions from "./conversions/pressureConversions";

type ConversionFunction = (value: number) => number;

/**
 * Round a number to the specified number of decimal places
 * @param value Number to be rounded
 * @param places Number of decimal places to round to
 * @returns Rounded number
 */
export function round(value: number, places: number): number {
    return Math.round(value * 10 ** places) / 10 ** places;
}

/**
 * Map of unit conversion functions, with base units as the first key and
 * target units as the second key. For example, unitConversions["°C"][°F"]
 * returns the function for converting Celsius into Fahrenheit.
 */
export const unitConversions: Record<
    string,
    Record<string, ConversionFunction>
> = {
    ...lengthConversions,
    ...temperatureConversions,
    ...pressureConversions,
};

/**
 * Convert a metric value from base units to target units
 * @param value Metric value to be converted
 * @param baseUnit Metric's base units to be converted from
 * @param targetUnit Metric's target units to be converted to
 * @returns The converted value
 */
export default function convertUnits(
    value: number,
    baseUnit: string,
    targetUnit: string,
): number {
    if (baseUnit === targetUnit) return value;
    return unitConversions[baseUnit][targetUnit](value);
}
