import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import OrganizationService from "services/OrganizationService";
import useQuery from "hooks/useQuery/useQuery";
import addEmbedParameter from "./utils/addEmbedParameter";

const AnalyticsContainerClassName =
    "w-full h-full flex justify-center items-center";

export default observer(function AnalyticsContainer(): JSX.Element {
    const { analyticId } = useQuery();
    const [status, setStatus] = useState<"loading" | "error" | "ready">(
        "loading",
    );
    const ref = useRef<HTMLIFrameElement | null>(null);

    const src = useMemo(() => {
        //check if the string is already formatted
        return analyticId && addEmbedParameter(analyticId);
    }, [analyticId, addEmbedParameter, status]);

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const res = await OrganizationService.getAnalyticsProxy();
                if (res.status == 200 && analyticId) {
                    setStatus("ready");
                } else {
                    setStatus("error");
                }
            } catch (error) {
                console.error(
                    `Error occurred while loading data for analyticId.`,
                );
                setStatus("error");
            }
        })();
    }, [analyticId]);

    if (!analyticId) {
        return (
            <div className={AnalyticsContainerClassName}>
                <span> Unable to load analytics data: ID is missing</span>
            </div>
        );
    }

    if (status === "loading") {
        return (
            <div className={AnalyticsContainerClassName}>
                <span>Loading...</span>
            </div>
        );
    }

    if (status === "error") {
        return (
            <div className={AnalyticsContainerClassName}>
                <span>Error: Failed to load analytics data</span>
            </div>
        );
    }

    return (
        <div className="w-full h-full">
            {src && (
                <iframe
                    id={"kibanaContainer"}
                    ref={ref}
                    key={analyticId}
                    src={src} // Add unique query parameter to the src URL
                    style={{ height: "100%", width: "100%" }}
                    // sandbox="allow-same-origin allow-scripts"
                />
            )}
        </div>
    );
});
