/**
 * Spaghetti Code
 *
 * Temporary formatting done to kibana analytic link to force a fullscreen view
 * without headers when rendering the iframe.
 *
 * This should ideally be done in the backend and this function removed.
 */

//URLs that should not show any filters
const flagUrlsNoFilter = [
    "https://kibana.srtlabs.com/app/dashboards#/view/e8abc8b0-e596-11ec-91b2-150fc7417dae?_g=(filters%3A!())",
];
function addEmbedParameter(url: string): string {
    // Check if URL is in flagUrlsNoFilter
    if (flagUrlsNoFilter.includes(url)) {
        const questionMarkIndex = url.indexOf("?");
        const gUnderscoreIndex = url.indexOf("_g");

        if (
            questionMarkIndex === -1 ||
            gUnderscoreIndex === -1 ||
            questionMarkIndex >= gUnderscoreIndex
        ) {
            // Return the original URL if it doesn't meet the criteria
            return url;
        }

        const firstPart = url.slice(0, questionMarkIndex + 1);
        const secondPart = url.slice(questionMarkIndex + 1, gUnderscoreIndex);
        const thirdPart = url.slice(gUnderscoreIndex);

        // Construct new URL with embed=true at the specified location and hide-filter-bar=true at the end
        const newUrl = `${firstPart}embed=true&${secondPart}${thirdPart}&hide-filter-bar=true`;
        return newUrl;
    }

    const urlObject = new URL(url);

    if (urlObject.searchParams.has("embed")) {
        //If the URL already has embedding, let's check the filters that are there and remove/add if necessary. then return as is
        if (urlObject.searchParams.has("show-time-filter=true")) {
            return url;
        } else if (urlObject.searchParams.has("show-query-input=false")) {
            return url;
        } else if (urlObject.searchParams.has("hide-filter-bar=true")) {
            return url;
        } else {
            // Otherwise, the url may have additional filters, not the ones we need, but it is embedded at least. Add the below
            // filters and return the filter as is. (end formatting)
            return (
                url +
                "show-time-filter=true&show-query-input=false&hide-filter-bar=true"
            );
        }
    }

    //Otherwise the URL needs to include embed=true at a specific point between ? and _g
    const questionMarkIndex = url.indexOf("?");
    const gUnderscoreIndex = url.indexOf("_g");

    // EDGE CASE: This returns the original URL without modification if it does not contain both the `?`
    // and `g_` characters, or if the`?` character occurs after the `g_` character,
    // to avoid processing errors. We don't know what this URL is, best not to mess with it.
    if (
        questionMarkIndex === -1 ||
        gUnderscoreIndex === -1 ||
        questionMarkIndex >= gUnderscoreIndex
    ) {
        return url;
    }

    // Split the URL into 3 parts, and insert the relevant filters in all the right places (the end).
    const firstPart = url.slice(0, questionMarkIndex + 1);
    const secondPart = url.slice(questionMarkIndex + 1, gUnderscoreIndex);
    const thirdPart = url.slice(gUnderscoreIndex);

    const newUrl = `${firstPart}embed=true&${secondPart}${thirdPart}&show-time-filter=true&show-query-input=false&hide-filter-bar=true`;
    return newUrl;
}

export default addEmbedParameter;
