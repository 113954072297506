import ToggleButtonGroupContext from "components/ToggleButtonGroup/ToggleButtonGroupContext";
import { ReactNode } from "react";

interface ToggleButtonGroupProps<T> {
    children: ReactNode;
    /**
     * The currently selected value in the group.
     */
    value: T;
    /**
     * Called when a new value is selected.
     */
    onChange: (value: T) => void;
}

/**
 * A group of toggle buttons that allows selection of a single value (out of 2 for now).
 * @template T - The type of the value used by the toggle buttons. Please mae sure you pass a type @example {<ToggleButtonGroup<string>>} for type-safety
 */
export default function ToggleButtonGroup<T>({
    children,
    value,
    onChange,
}: ToggleButtonGroupProps<T>): JSX.Element {
    return (
        <ToggleButtonGroupContext.Provider value={{ value, onChange }}>
            <div className="inline-flex rounded-md shadow-sm group">
                {children}
            </div>
        </ToggleButtonGroupContext.Provider>
    );
}
