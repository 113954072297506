import Accordion from "components/Accordion/Accordion";
import DisplayAngleIcon from "components/DisplayAngleIcon/DisplayAngleIcon";
import { useNavigateToLocation } from "hooks/useNavigateToLocation";
import useToggleToAccordion from "hooks/useToggleToAccordion/useToggleToAccordion";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import extendClassName from "utils/extendClassName";
import type LocationProps from "./utilities/LocationProps";
type Locations = {
    id: string;
    text: string;
    sublocations: Sublocation[];
};

type Sublocation = {
    id: string;
    text: string;
};

//We want to alphabetically sort all sublocations if the belong to the PSNS MS org
const includeAlphabeticalSort = ["14ee2d03-878b-47bc-b678-ecf97723c488"];

/**
 * The container for the Locations Content from the LeftNavDrawer.
 * It includes list of locations and their relevant sublocations as an accordion of
 * list items.
 */
export default observer(function Locations({
    organizationStore,
    setCurrentTab,
    setDrawerOpen,
    setDrawerItemClicked,
}: LocationProps) {
    const { navigateToLocation } = useNavigateToLocation();
    const [searchParams] = useSearchParams();
    const { handleToggle, isOpen, initialAccordionState } =
        useToggleToAccordion();
    const locations = useMemo(
        () =>
            Object.entries(organizationStore.organization?.locations || {})
                .map(([id, loc]) => ({
                    id,
                    text: loc.name,
                    listOrderNumber: loc.listOrderNumber,
                    sublocations: Object.entries(loc.sublocations)
                        .map(([sublocationID, sublocation]) => ({
                            id: sublocationID,
                            text: sublocation.name,
                            listOrderNumber: sublocation.listOrderNumber,
                        }))
                        .sort((a, b) => a.listOrderNumber - b.listOrderNumber),
                }))
                .sort((a, b) => a.listOrderNumber - b.listOrderNumber),
        [organizationStore.organization],
    );

    useEffect(() => {
        // The first location accordion is always open by default
        if (locations.length > 0) initialAccordionState(locations[0].id);
    }, []);

    /**
     * Renders the list of locations and sublocations.
     */
    const renderSubNavList = useCallback(
        (locId: string, sublocations: Sublocation[]): JSX.Element => {
            const sublocationList =
                organizationStore.orgId &&
                includeAlphabeticalSort.includes(organizationStore.orgId)
                    ? sublocations.sort((a, b) => a.text.localeCompare(b.text))
                    : sublocations;

            return (
                <ul className="text-[#626262] max-h-[80%] overflow-y-auto  font-sans">
                    {sublocationList.map(
                        ({ id: sublId, text }: Sublocation) => {
                            return (
                                <li
                                    key={text + sublId}
                                    className={extendClassName(
                                        "decoration-none p-4 cursor-pointer text-base text-obsidian hover:text-cobalt",
                                        sublId === searchParams.get("sublId")
                                            ? "shadow-inner-blue bg-gradient-to-r from-transparent to-gray-200"
                                            : "",
                                    )}
                                    onClick={(): void => {
                                        setDrawerItemClicked(true);
                                        setDrawerOpen(false);
                                        setTimeout(
                                            (): void => setCurrentTab(null),
                                            600,
                                        ); // this timeout exists to coincide with the animation delay in the LeftNavDrawer
                                        navigateToLocation("maps", {
                                            orgId: organizationStore.orgId,
                                            locId: locId,
                                            sublId: sublId,
                                        });
                                    }}
                                >
                                    {text}
                                </li>
                            );
                        },
                    )}
                </ul>
            );
        },
        [organizationStore.orgId, searchParams],
    );

    return (
        <div className="text-obsidian h-full overflow-hidden">
            {organizationStore.organization?.locations
                ? locations.map(
                      ({ text, id: locId, sublocations }: Locations) => {
                          return (
                              <Accordion
                                  key={locId}
                                  isOpen={isOpen[locId]}
                                  setIsOpen={(): void => {
                                      handleToggle(locId);
                                  }}
                                  classes={{
                                      wrapper: extendClassName(
                                          "w-full h-auto bg-white text-[#414141]  font-sans text-base p-2 flex justify-between cursor-pointer hover:text-cobalt",
                                          locId === searchParams.get("locId") &&
                                              !searchParams.get("sublId")
                                              ? "shadow-inner-blue bg-gradient-to-r from-transparent via-white to-gray-200"
                                              : "",
                                      ),
                                  }}
                                  data={text}
                                  renderHeader={({
                                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                      item,
                                      onClickItem,
                                  }): JSX.Element => (
                                      <span
                                          data-title={text}
                                          className="pl-3 py-2 flex flex-row space-between w-full"
                                          onClick={(): void => {
                                              onClickItem(locId);
                                              navigateToLocation("maps", {
                                                  orgId: organizationStore.orgId,
                                                  locId: locId,
                                              });
                                          }}
                                      >
                                          <span className="text-base font-semibold">
                                              {text}
                                          </span>

                                          <button
                                              className={
                                                  "ml-auto min-w-[2em] pointer-events-none"
                                              }
                                              onClick={(): void =>
                                                  onClickItem(locId)
                                              }
                                          >
                                              {
                                                  <DisplayAngleIcon
                                                      isOpen={isOpen[locId]}
                                                  />
                                              }
                                          </button>
                                      </span>
                                  )}
                              >
                                  {renderSubNavList(locId, sublocations)}
                              </Accordion>
                          );
                      },
                  )
                : "There are no locations available."}
        </div>
    );
});
