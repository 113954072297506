import { memo, useEffect, useMemo, useState } from "react";
import EditDrawerProps from "../utilities/EditDrawer.props";
import MultiRangeUnitConversionMetric from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemNonGeneric/MultiRangeUnitConversionMetricContainer/MultiRangeUnitConversionMetric/MultiRangeUnitConversionMetric";
import type MULTI_RANGE_UNIT_CONVERSION_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MULTI_RANGE_UNIT_CONVERSION_DIMENSION.type";
import MultiRangeUnitConversionEditableContent from "./MultiRangeUnitConversionEditableContent/MultiRangeUnitConversionEditableContent";
import MultiRangeUnitConversion from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MultiRangeUnitConversion.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import convertUnits, { round } from "utils/unitConversions/unitConversions";
import convertMultiRangeUnitConversionDimension from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemNonGeneric/MultiRangeUnitConversionMetricContainer/utilities/convertMultiRangeUnitConversionDimension";

export default memo(function MultiRangeUnitConversionEditDrawer({
    value,
    drawer,
    disabled,
    onChange,
    options,
    setHasErrors,
}: EditDrawerProps<
    number,
    MULTI_RANGE_UNIT_CONVERSION_DIMENSION
>): JSX.Element {
    const dimension = options.multiRangeUnitConversion;

    const [formState, setFormState] =
        useState<MultiRangeUnitConversion>(dimension);

    useEffect(() => {
        onChange({ [DISPLAY_TYPE.MULTI_RANGE_UNIT_CONVERSION]: formState });
    }, [formState]);

    const [unit, setUnit] = useState(drawer.unit);

    useEffect(() => {
        drawer.unit = unit;
    }, [unit]);

    const convertedMetricValue = useMemo(() => {
        return round(convertUnits(value, dimension.baseUnits, unit), 1);
    }, [unit]);

    const convertedDimension = useMemo(() => {
        return convertMultiRangeUnitConversionDimension(dimension, unit);
    }, [unit, dimension]);

    return (
        <>
            <div className="flex w-full my-8">
                <MultiRangeUnitConversionMetric
                    value={convertedMetricValue}
                    unit={unit}
                    {...convertedDimension}
                />
            </div>

            <span className="text-center">
                Default Reading: <i>{options.multiRangeUnitConversion!.base}</i>
            </span>

            {!disabled ? (
                <MultiRangeUnitConversionEditableContent
                    onChange={onChange}
                    dimension={options}
                    unit={unit}
                    baseUnits={dimension.baseUnits}
                    setUnit={setUnit}
                    setHasErrors={setHasErrors}
                    setFormState={setFormState}
                />
            ) : null}
        </>
    );
});
