import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import InputField from "components/InputField/InputField";
import useSaveDevice from "hooks/useSaveDevice.hook";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import rootStore from "stores/rootStore";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import CurrentDeviceCardContentEditController from "../CurrentDeviceCardContentEdit/CurrentDeviceCardContentEditController/CurrentDeviceCardContentEditController";
import type CurrentDeviceCardContentMapProps from "./utilities/CurrentDeviceCardContentMap.props";
import MAP_POSITION_KEYS from "./utilities/MAP_POSITION_KEYS.data";

export default observer(function CurrentDeviceCardContentMap({
    device,
}: CurrentDeviceCardContentMapProps): JSX.Element {
    const onlyDigitDotsAndDashes = /[^-0-9.]+/;
    //We want to allow empty strings as valid inputs for the form table
    //hence why we convert the inherit positions to strings here
    const DevicePositionBaseData = useMemo(() => {
        return {
            x: String(device.position.x),
            y: String(device.position.y),
        };
    }, [device.position]);
    const [fetching, setFetching] = useState(false);
    const [position, setPosition] = useState<{ x: string; y: string }>(
        DevicePositionBaseData,
    );
    const [editing, setEditing] = useState(false);

    //Indicates the REST call
    const { mutate: saveDevice } = useSaveDevice(device);
    const { enqueueSnackbar } = useSnackbar();

    const disabled = useMemo(() => fetching, [fetching]);

    const canEditPosition = useMemo(() => {
        return device.display.displaySettings.canEditPosition ? false : true;
    }, [device.display.displaySettings.canEditPosition]);

    /**
     * Updates the device position on the map via the openlayers store
     */
    const updateDevicePositionOnMap = useCallback(
        (
            device: Device | MobileDevice | GroupedDevice,
            position: { x: number; y: number },
        ) => {
            rootStore.openLayersStore.updateFeaturePosition(device, position);
        },
        [],
    );

    /**
     * Resets the device position to whatever was last saved from the latest pulled device object
     */
    const resetDevicePosition = useCallback(() => {
        rootStore.openLayersStore.updateFeaturePosition(
            device,
            device.position,
        );
        setPosition(DevicePositionBaseData);
    }, [device.position]);

    const updateDevicePosition = useCallback(
        (key: "x" | "y", value: string) => {
            try {
                //Don't update the device position if the map is not actively showing devices;
                const mapType = rootStore.openLayersStore.mapType;
                if (
                    mapType === MAP_VIEW_TYPE.ZONE ||
                    mapType === MAP_VIEW_TYPE.SUBLOCATION_OVERLAY
                )
                    return;
                const newValue = value.replace(onlyDigitDotsAndDashes, "");
                //Update the local state for the input fields
                setPosition((state) => ({
                    ...state,
                    [key]: newValue,
                }));

                //Update the relevant delta value for the map
                if (key === "x")
                    updateDevicePositionOnMap(device, {
                        ...device.position,
                        x: isNaN(Number(value)) ? 0 : Number(newValue),
                    });
                if (key === "y")
                    updateDevicePositionOnMap(device, {
                        ...device.position,
                        y: isNaN(Number(value)) ? 0 : Number(newValue),
                    });
            } catch (err) {
                console.error("Could not update device position", err);
            }
        },
        [
            device.position,
            saveDevice,
            updateDevicePositionOnMap,
            rootStore.openLayersStore.mapType,
        ],
    );

    const save = useCallback(async (): Promise<void> => {
        if (fetching) return;
        try {
            setFetching(true);
            device.position = {
                ...device.position,
                x: isNaN(Number(position.x)) ? 0 : Number(position.x),
                y: isNaN(Number(position.y)) ? 0 : Number(position.y),
            };
            await saveDevice({
                ...device,
            });
        } catch (e) {
            console.error(e);
        } finally {
            setFetching(false);
        }
    }, [device, position, enqueueSnackbar, fetching]);

    useEffect(() => {
        //Reset the device position on component dismount for
        //unsaved map position updates
        //Don't update the device position if the map is not actively showing devices;
        const mapType = rootStore.openLayersStore.mapType;
        if (
            mapType === MAP_VIEW_TYPE.ZONE ||
            mapType === MAP_VIEW_TYPE.SUBLOCATION_OVERLAY
        )
            return;
        return (): void => resetDevicePosition();
    }, [rootStore.openLayersStore.mapType]);

    return (
        <div className="flex flex-1 flex-col">
            <div className="flex flex-1 flex-col mx-8">
                {MAP_POSITION_KEYS.map(
                    (key: "x" | "y"): JSX.Element => (
                        <InputField
                            classes={{
                                label: "my-4 capitalize",
                                error: "text-cobalt",
                            }}
                            key={key}
                            type="text"
                            label={"position: " + key}
                            value={position[key]}
                            disabled={canEditPosition || disabled || !editing}
                            onChange={({ target: { value } }): void =>
                                updateDevicePosition(key, value)
                            }
                        />
                    ),
                )}
            </div>

            <CurrentDeviceCardContentEditController
                saving={fetching}
                saveable={!fetching}
                save={async (): Promise<void> => {
                    setEditing(false);
                    await save();
                }}
                error={""} //There is no error handling done for this input field
                editing={editing}
                setEditing={(): void => setEditing(true)}
                onCancel={(): void => {
                    resetDevicePosition();
                    setEditing(!editing);
                }}
                disabled={disabled || canEditPosition}
                text={{
                    cancel: "Cancel",
                    saveText: "Save",
                    edit: "Edit Device Position",
                }}
            />
        </div>
    );
});
