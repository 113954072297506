import { memo } from "react";
import { unitConversions } from "utils/unitConversions/unitConversions";
type UnitSelectorProps = {
    unit: string;
    baseUnit: string;
    setUnit: (unit: string) => void;
};

export default memo(function UnitSelector({
    unit,
    baseUnit,
    setUnit,
}: UnitSelectorProps): JSX.Element {
    // Get unit options from conversion keys/possibilities from base units,
    // i.e., keys for all unit conversion functions supported by the base units.
    // This only allows the user to select supported conversions.
    const options = Object.keys(unitConversions[baseUnit]);

    return (
        <select
            className="bg-transparent"
            value={unit}
            onChange={(e): void => setUnit(e.target.value)}
        >
            <option key={baseUnit} value={baseUnit}>
                {baseUnit}
            </option>

            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
});
