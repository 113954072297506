import type DeviceStateDimension from "@srtlabs/m1_types/lib/Device/DeviceState/DeviceStateDimensions/DeviceStateDimension/DeviceStateDimension.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import { memo, useCallback } from "react";
import isValidLinearScaledMultiRangeSafeVal from "utils/MetricValueVerifyUtils/isValidLinearScaledMultiRangeSafeVal";
import isValidMultiRangeSafeVal from "utils/MetricValueVerifyUtils/isValidMultiRangeSafeVal";
import BooleanToStringEditDrawer from "./BooleanToStringEditDrawer/BooleanToStringEditDrawer";
import InAndOutOfRangeToStringEditDrawer from "./InAndOutOfRangeToStringEditDrawer/InAndOutOfRangeToStringEditDrawer";
import LinearScaledMultiRangeSafeValEditDrawer from "./LinearScaledMultiRangeSafeValEditDrawer/LinearScaledMultiRangeSafeValEditDrawer";
import MinMaxSafeImperialRangeEditDrawer from "./MinMaxSafeImperialRangeEditDrawer/MinMaxSafeImperialRangeEditDrawer";
import MinMaxSafeRangeEditDrawer from "./MinMaxSafeRangeEditDrawer/MinMaxSafeRangeEditDrawer";
import MultiRangeSafeValEditDrawer from "./MultiRangeSafeValEditDrawer/MultiRangeSafeValEditDrawer";
import MultiRangeUnitConversionEditDrawer from "./MultiRangeUnitConversionEditDrawer/MultiRangeUnitConversionEditDrawer";
import CurrentDeviceCardContentEditDimensionsItemProps from "./utilities/CurrentDeviceCardContentEditDimensionsItem.props";

export default memo(function CurrentDeviceCardContentEditDimensionsItem({
    value,
    drawer,
    disabled,
    onChange,
    dimension,
    setHasErrors,
}: CurrentDeviceCardContentEditDimensionsItemProps): JSX.Element {
    const editDrawerOnChange = useCallback(
        (options: DeviceStateDimension[keyof DeviceStateDimension]): void => {
            onChange({
                ...dimension,
                [drawer.type]: options,
            });
        },
        [onChange, dimension, drawer.type],
    );

    if (dimension?.[drawer.type] === undefined) {
        return (
            <h4 className="m-2 my-auto w-full text-center">
                Display error, please contact SRT support. UNDEFINED
            </h4>
        );
    }

    if (
        (drawer.type === DISPLAY_TYPE.MULTI_RANGE_SAFE_VAL ||
            drawer.type === DISPLAY_TYPE.RANGE_FLOAT ||
            drawer.type === DISPLAY_TYPE.RANGE_INTEGER) &&
        isValidMultiRangeSafeVal(dimension[drawer.type])
    ) {
        return (
            <MultiRangeSafeValEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={Number(value)}
                setHasErrors={setHasErrors}
            />
        );
    }

    if (
        drawer.type === DISPLAY_TYPE.MULTI_RANGE_UNIT_CONVERSION &&
        dimension[drawer.type]?.multiRangeUnitConversion
    ) {
        return (
            <MultiRangeUnitConversionEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={Number(value)}
                setHasErrors={setHasErrors}
            />
        );
    }

    if (
        drawer.type === DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL &&
        isValidLinearScaledMultiRangeSafeVal(dimension[drawer.type])
    ) {
        return (
            <LinearScaledMultiRangeSafeValEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={Number(value)}
                setHasErrors={setHasErrors}
            />
        );
    }

    if (
        (drawer.type === DISPLAY_TYPE.RANGE_FLOAT ||
            drawer.type === DISPLAY_TYPE.RANGE_INTEGER) &&
        dimension[drawer.type]?.minMaxSafeRange
    ) {
        return (
            <MinMaxSafeRangeEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={Number(value)}
                setHasErrors={setHasErrors}
            />
        );
    }

    if (
        drawer.type === DISPLAY_TYPE.MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE &&
        dimension[drawer.type]?.minMaxSafeValImperialTemperature
    ) {
        return (
            <MinMaxSafeImperialRangeEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={Number(value)}
                setHasErrors={setHasErrors}
            />
        );
    }

    if (
        drawer.type === DISPLAY_TYPE.BOOLEAN_TO_STRING &&
        dimension[drawer.type]?.booleanToString
    ) {
        return (
            <BooleanToStringEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={value === true || value === "true"}
                setHasErrors={setHasErrors}
            />
        );
    }

    if (
        drawer.type === DISPLAY_TYPE.IN_AND_OUT_OF_RANGE_TO_STRING &&
        dimension[drawer.type]?.inAndOutOfRangeToString
    ) {
        return (
            <InAndOutOfRangeToStringEditDrawer
                drawer={drawer}
                onChange={editDrawerOnChange}
                disabled={disabled}
                options={dimension[drawer.type]!}
                value={Number(value)}
                setHasErrors={setHasErrors}
            />
        );
    }

    return (
        <h4 className="m-2 my-auto w-full text-center">
            Display error, please contact SRT support. GENERIC
        </h4>
    );
});
