import type Access from "@srtlabs/m1_types/lib/Access/Access.type";
import type AccessOrganization from "@srtlabs/m1_types/lib/Access/AccessOrganization/AccessOrganization.type";
import type Location from "@srtlabs/m1_types/lib/Organization/Locations/Location/Location.type";
import LocationMap from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMap.type";
import AnalyticsDashboardLink from "@srtlabs/m1_types/lib/Organization/Locations/Location/Sublocations/Sublocation/AnalyticsDashboardLink/AnalyticsDashboardLink.type";
import type Sublocation from "@srtlabs/m1_types/lib/Organization/Locations/Location/Sublocations/Sublocation/Sublocation.type";
import SublocationMap from "@srtlabs/m1_types/lib/Organization/Locations/Location/Sublocations/Sublocation/SublocationMaps/SublocationMap/SublocationMap.type";
import {
    action,
    computed,
    IReactionDisposer,
    makeAutoObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";

/**
 * mobx store for an Organization / Access object
 * handles what is essentially a reactive @see Access object
 */
export default class OrganizationStore {
    private reactionDisposers: IReactionDisposer[] = [];

    /**
     * reactive @see Access object
     */
    @observable
    public access: Access = {};

    /**
     * reactive organization id
     */
    @observable
    public orgId = "";

    /**
     * reactive location id
     */
    @observable
    public locId = "";

    /**
     * reactive sublocation id
     */
    @observable
    public sublId = "";

    @observable
    public zoneId = "";

    /**
     * Analytic links derived from the current sublocation or location.
     * This set when the sublocation changes
     * @see ReactionManager
     */
    @observable
    private _analytics: AnalyticsDashboardLink[] | null = null;

    @computed
    get analytics(): AnalyticsDashboardLink[] | null {
        return this._analytics;
    }

    /**
     * reactive @see AccessOrganization object
     */
    @computed
    get organization(): AccessOrganization | null {
        return this.access[this.orgId] || null;
    }

    /**
     * reactive @see Location object
     */
    @computed
    get location(): Location | null {
        return this.organization?.locations[this.locId] || null;
    }

    /**
     * Contains all available maps for a specific location
     */
    @computed
    get activeLocationMaps(): [string, LocationMap][] {
        if (this.location) {
            return Object.entries(this.location.maps);
        }
        return [];
    }

    /**
     * All devices found under the current sublocation if present, or if there is no sublId,
     * all devices from the entire location
     */
    @computed
    get activeDevices(): string[] {
        if (this.sublocation) {
            return this.sublocation?.devices || [];
        } else if (this.location) {
            return Object.values(this.location?.sublocations || {}).flatMap(
                (subloc) => subloc.devices || [],
            );
        } else {
            return [];
        }
    }

    /**
     * All devices found under the current location if present
     */
    @computed
    get locationDevices(): string[] {
        if (this.location) {
            return Object.values(this.location?.sublocations || {}).flatMap(
                (subloc) => subloc.devices || [],
            );
        } else {
            return [];
        }
    }

    @computed
    get activeZones(): string[] {
        if (this.sublocation) {
            return this.sublocation.zones || [];
        } else if (this.location) {
            return Object.values(this.location.sublocations || {}).flatMap(
                (subloc) => subloc.zones || [],
            );
        }
        return [];
    }

    /**
     * Get a map of {zoneID: sublocation name} for all zones in the current
     * location or sublocation. We need to get the name from the sublocation
     * the zone is stored under, as zones do not store this information in
     * the zone document itself.
     */
    @computed
    get zoneSublocationNames(): Record<string, string> {
        const sublocationNames: Record<string, string> = {};
        if (this.sublocation) {
            if (!this.sublocation.zones) return {};
            for (const zone of this.sublocation.zones)
                sublocationNames[zone] = this.sublocation.name;
        } else if (this.location) {
            if (!this.location.sublocations) return {};
            for (const sublocation of Object.values(
                this.location.sublocations,
            )) {
                if (!sublocation.zones) continue;
                for (const zone of sublocation.zones)
                    sublocationNames[zone] = sublocation.name;
            }
        }
        return sublocationNames;
    }

    /**
     * Get the sublocation name for a specific zone based on the zoneId.
     * @param zoneId zoneId.
     * @returns The sublocation name associated with the zone, or an empty string if not found (i don't think this should throw an error).
     */
    @computed
    public getSublocationNameByZoneId(zoneId: string): string {
        if (this.sublocation) {
            if (!this.sublocation.zones) return "";
            if (this.sublocation.zones.includes(zoneId))
                return this.sublocation.name;
        } else if (this.location) {
            if (!this.location.sublocations) return "";
            for (const sublocation of Object.values(
                this.location.sublocations,
            )) {
                if (!sublocation.zones) continue;
                if (sublocation.zones.includes(zoneId)) return sublocation.name;
            }
        }
        return "";
    }

    /**
     * reactive @see Sublocation object
     */
    @computed
    get sublocation(): Sublocation | null {
        return this.location?.sublocations[this.sublId] || null;
    }

    /**
     * Contains all the available maps for an active sublocation
     */
    @computed
    get activeSublocationMaps(): [string, SublocationMap][] {
        if (this.sublocation) {
            const { maps } = this.sublocation;

            return Object.entries(maps);
        }

        return [];
    }

    /**
     * sets @see this.access
     */
    @computed
    get orgName(): string | null {
        return this.access[this.orgId]?.name || "";
    }

    @computed
    get locName(): string | null {
        return this.organization?.locations[this.locId]?.name || "";
    }

    @computed
    get sublName(): string | null {
        return this.location?.sublocations[this.sublId]?.name || "";
    }

    /**
     * retrieves analytics for a given sublocation and location
     * @param {string} sublId
     * @param {string} locId
     * @returns {AnalyticsDashboardLink[] | null} null or array of analytic dashboard links
     */
    @action
    public getAnalyticLinks(
        sublId: string,
        locId: string,
    ): AnalyticsDashboardLink[] | null {
        return (
            this.organization?.locations[locId].sublocations[sublId]
                ?.analyticsDashboardLinks || null
        );
    }

    @action
    public getLocationMap(key: string): LocationMap | null {
        const map = this.activeLocationMaps.find(([mapKey]) => mapKey === key);
        return (map && map[1]) || null;
    }

    /**
     * Reactive. Returns a list of device ids that are not unique to a specific sublocation
     * and can move from one sublocation to another
     */
    @computed
    get mobileDevices(): string[] {
        return this.organization?.locations[this.locId]?.mobileDevices || [];
    }

    public _setAccess(access: Access): void {
        runInAction(() => (this.access = access));
    }

    /**
     * sets @see orgId
     */
    public _setOrgID(id: string): void {
        this.orgId = id;
    }

    /**
     * sets @see this.locId
     */
    public _setLocID(id: string): void {
        this.locId = id;
    }

    /**
     * @see this.sublId
     */
    public _setSublID(id: string): void {
        this.sublId = id;
    }

    public _setZoneID(id: string): void {
        this.zoneId = id;
    }

    public updateAnalytics(): void {
        runInAction(() => {
            if (this.sublocation) {
                this._analytics =
                    this.sublocation.analyticsDashboardLinks || null;
            } else if (this.location) {
                this._analytics = this.location.analyticsDashboardLinks || null;
            } else {
                this._analytics = null;
            }
        });
    }

    private setupReactions(): void {
        this.reactionDisposers.push(
            reaction(
                () => ({ loc: this.location, subloc: this.sublocation }),
                ({ loc, subloc }) => {
                    runInAction(() => {
                        if (subloc) {
                            this._analytics =
                                subloc.analyticsDashboardLinks || null;
                        } else if (loc) {
                            this._analytics =
                                loc.analyticsDashboardLinks || null;
                        } else {
                            this._analytics = null;
                        }
                    });
                },
                { fireImmediately: true },
            ),
        );
    }
    public dispose(): void {
        this.reactionDisposers.forEach((disposer) => disposer());
    }

    public reinitalizeReactions(): void {
        this.dispose();
        this.setupReactions();
    }

    public constructor() {
        makeAutoObservable(this);

        this.reactionDisposers = [];
        this.setupReactions();
    }
}
