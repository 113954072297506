/**
 * This logic has been moved to the deviceStore, mobX no longer acknowledges
 * state updates outside the store in version 6+
 * @param data
 * @param value
 * @param field
 */
const setDeepValue = (
    data: Record<string, unknown>,
    value: unknown,
    field: string, // field = 'metrics.turned_on.value'
): void => {
    // schema = reference to obj
    let schema = data;

    // split keys from dot notation
    const keys = field.split(".");
    // get length of keys - 1 for easy access to last element
    const len = keys.length - 1;

    // loop through keys and set the value by reference
    // if that element doesn't exist set it to an object.
    for (let i = 0; i < len; i++) {
        const elem = keys[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem] as Record<string, unknown>;
    }

    // set the schema[fields.split()[last]] = value;
    schema[keys[len]] = value;
};

export default setDeepValue;
