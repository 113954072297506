export const MOBILE_CLASS_NAMES = {
    iconTabs: {
        container: "w-8",
        icon: "",
        text: "",
        onHover: "",
        onHoverText:
            " text-xs sm:text-sm mx-auto absolute top-5/6 w-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center capitalize text-obsidian hidden group-hover:block",
    },
    flexColumn: "flex flex-col",
    paddingStandard: "px-4 py-4",
    marginTop: "mt-4",
    marginBottom: "mb-4",
    buttonStandard: "bg-blue-500 px-4 py-2 rounded text-white",

    // More classes as needed...
};

/**
 * There are a multitude of components that share long style names, in order to share consistenty, when can store them here.
 */
export const CLASS_NAMES = {
    //Tabs that appear in an column such as LEFTNAVDRAWER, DEVICE, ZONEHVAC, and ZONESIMPLE
    verticalSeperator: "mx-1 text-bold",

    iconTabs: {
        container: "flex flex-col w-full h-full py-4 w-[4em] lg:w-[92px]",
        zoneAndDeviceTabs:
            "h-[100px] py-4 relative text-center duration-300 transition-colors group flex flex-col align-center justify-center ",
        icon: "w-full",
        text: "  text-xs sm:text-sm mx-auto absolute top-5/6 w-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center capitalize text-obsidian ",
        onHover: "",
        onHoverText: " hidden group-hover:block font-semibold",
        disabled: " pointer-events-none opacity-40",
    },
    //Includes DEVICE, ZONEHVAC, and ZONESIMPLE drawer styling
    drawer: {
        drawerHeaderTitle:
            "font-nunito text-lg max-md:min-h-[30px] truncate font-semibold",
        clickOutsideContainer:
            "absolute max-md:top-[4.75rem] bottom-0 right-0 left-0 w-full h-full z-30 overflow-x-hidden cursor-pointer flex bg-transparent bg-opacity-40 hidden sm:block",
        drawerContainer:
            "absolute max-md:top-[4.75rem] left-0 sm:right-0 sm:left-auto max-md:min-w-fit flex flex-row h-full text-obsidian lg:w-[871px] z-[100] bg-white",
        tabsContainer:
            "flex w-4 lg:w-[92px] min-w-[4rem] flex-col border-x-1 border-l-grey-700 border-r-gray-700 bg-gray-100",
        overallContentWrapper:
            "xs:overflow-x-auto lg:overflow-x-hidden flex lg:flex-1",
        overallContentContainer:
            "flex flex-col flex-1 overflow-x-auto sm:overflow-x-visible bg-gray-100 max-md:min-w-max",
        logsContainer: "flex flex-col justify-start mb-20 px-[1.125em]",
        content: {
            title: "text-lg py-4 pl-16 my-auto min-w-25",
            titleContainer: "flex flex-row py-4",
        },
    },
};

export const FORM_CLASSNAMES = {
    input: "appearance-none w-full border-1 border-gray-700 p-2 leading-tight focus:outline-none bg-white",
    error: "text-orange text-xs italic",
    label: "p-0 relative appearance-none border-1 border-white border-opacity-30 rounded focus:shadow-outline",
    span: "capitalize py-2 text-sm text-gray-700",
};
